<template>
  <v-btn 
    depressed
    :block="!noBlock" 
    :class="
    [
      leftFlat ? 'rounded-l-0' : '',
      rightFlat ? 'rounded-r-0' : '',
      topFlat ? 'rounded-t-0' : ''
    ]" 
    :color="white ? 'white' : 'turquesa'" 
    light
    :href="gitbook_url"
    @click.stop="stop"
    target="_blank"
    min-width="150px">
    <v-img class="mr-2" max-width="10px" src="/assets/ic-arrow-blue-right.svg" />
    <span class="font-weight-bold">{{ title }}</span>
    </v-btn>
</template>

<script>
const gitbook_url = process.env.VUE_APP_GITBOOK_URL;
export default {
  name: 'SaberMasButton',
  props: {
    white: {
      type: Boolean,
      default: false
    },
    leftFlat: {
      type: Boolean,
      default: false
    },
    rightFlat: {
      type: Boolean,
      default: false
    },
    topFlat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'SABER MÁS'
    },
    noBlock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gitbook_url
    }
  },
  methods: {
    stop() {

    }
  }
}
</script>

<style>

</style>